module.exports = {
  'united-kingdom': [
    '447940050207',
    '447893932971',
    '447700185170',
    '447876517058',
    '447403935706',
    '447563508112',
    '447563508111',
    '447440788561',
    '447411297599',
    '447488803793',
    '447700150509',
    '447471445108',
    '447700150561',
    '447787934902',
    '447883302122'
  ],
  'united-states': [
    '12085815140',
    '12085813846',
    '12184147191',
    '12256110266',
    '12132935387',
    '121100000160',
    '12604743876',
    '14700528396',
    '18782635831',
    '14123175865',
    '15411877023',
    '16590159710',
    '15311641430',
    '14144169688',
    '17089356539'
  ],
  canada: ['12494881412', '12268356895', '15068027643', '12267414466', '15878715669', '15484905289', '15064043318', '12268285928'],
  australia: ['61485906541', '61420190767', '61423886176', '61409425134', '61448987461']
}
